import { createRouter, createWebHistory } from 'vue-router';

import Login from '/src/components/pages/Login.vue';
import UserSetup from '/src/components/pages/UserSetup.vue';
import ForgotPassword from '/src/components/pages/ForgotPassword.vue';
import ChangePassword from '/src/components/pages/ChangePassword.vue';
import PassThrough from '/src/components/PassThrough.vue';
import AdminPassThrough from '/src/components/pages/beta/layouts/admin/AdminPassThrough.vue';


// Dashboard Layout
import DashboardLayout from '/src/components/pages/beta/layouts/Root.vue';

// Retainer Pages
import RetainerLayout from '/src/components/pages/beta/layouts/retainer/Root.vue';
import RetainerReportsLayout from '/src/components/pages/beta/layouts/retainer/Reports.vue';

import Dashboard from '/src/components/pages/beta/retainer/Dashboard.vue';
import RetainerSettings from '/src/components/pages/beta/retainer/Edit.vue';
import Services from '/src/components/pages/beta/retainer/Services.vue';
import SendMail from '/src/components/pages/SendMail.vue';
import Account from '/src/components/pages/beta/retainer/Account.vue';
import Contacts from '/src/components/pages/beta/retainer/Contacts.vue';

// Reports Pages
import ReportsOverview from '/src/components/pages/beta/retainer/reports/Overview.vue';
import ReportsGraph from '/src/components/pages/beta/retainer/reports/Graph.vue';
import ReportsListing from '/src/components/pages/beta/retainer/reports/Index.vue';
import EditListing from '/src/components/pages/beta/retainer/reports/EditListing.vue';
import SendReport from '/src/components/pages/beta/retainer/reports/SendReport.vue';

// Admin Pages
import DeveloperOptions from '/src/components/pages/beta/admin/DeveloperOptions.vue';
import TeamDashboard from '/src/components/pages/beta/admin/TeamDashboard.vue';
import AdminDashboard from '/src/components/pages/beta/admin/dashboard/Dashboard.vue';
import ClientsNeedingAttention from '/src/components/pages/beta/admin/dashboard/ClientsNeedingAttention.vue';
import RetainersToWatch from '/src/components/pages/beta/admin/dashboard/RetainersToWatch.vue';

import ClientRetainers from '/src/components/pages/beta/admin/clients/ClientRetainers.vue';
import RetainerNewClient from '/src/components/pages/beta/admin/clients/AddClient.vue';

import RetainersOverview from '/src/components/pages/RetainersOverview.vue';
import RetainersOverviewv2 from '/src/components/pages/beta/admin/retainers/Dashboard.vue';
import AddInvoice from '/src/components/pages/beta/retainer/reports/AddInvoice.vue';

import Users from '/src/components/pages/Users.vue';
import UserProfile from '/src/components/pages/UserProfile.vue';
import AddNewUser from '/src/components/objects/AddUserForm.vue';
import AdminProfile from '/src/components/objects/AdminProfile.vue';

import SettingsDashboard from '/src/components/pages/beta/admin/SettingsDashboard.vue';
import ApplicationSettings from '/src/components/pages/beta/admin/ApplicationSettings.vue';
import AdminUsers from '/src/components/pages/AdminUsers.vue';
import AuditLogs from '/src/components/pages/beta/admin/AuditLogs.vue';

const routes = [
    {
        path: '/',
        redirect: { name: 'Login' }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        props: true
    },
    {
        path: '/user-setup',
        name: 'UserSetup',
        component: UserSetup
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
    },
    {
        path: '/change-password/:userid',
        name: 'ChangePassword',
        component: ChangePassword
    },
    {
        path: '/beta-dashboard/:pathMatch(.*)*',
        component: PassThrough,
        name: 'Dashboard Backup Redirect',
        redirect: { name: 'Beta Dashboard' },
    },
    {
        path: '/dashboard',
        name: 'Beta Dashboard',
        component: DashboardLayout,
        props: true,
        children: [
            {
                path: ':clientId',
                name: 'Retainer Dashboard',
                component: PassThrough,
                redirect: (to) => {
                    try {
                        if(window.location.pathname) {
                            return {
                                path: window.location.pathname
                            }
                        }
                    } catch(error) {
                        const latestUrlBeforeLogout = sessionStorage.getItem("latest-url-before-logout")?.toString();
                        if(latestUrlBeforeLogout) {
                            sessionStorage.removeItem("latest-url-before-logout");
                            return {
                                path: latestUrlBeforeLogout
                            }
                        }
                    }
                },
                props: true,
                children: [
                    {
                        path: 'retainer/:retainerId',
                        name: 'Retainer Dashboard',
                        component: RetainerLayout,
                        redirect: { name: 'Beta Dashboard Home' },
                        props: true,
                        children: [
                            {
                                path: '',
                                name: 'Beta Dashboard Home',
                                props: true,
                                component: Dashboard
                            },
                            {
                                path: 'reports',
                                name: 'Beta Dashboard Reports',
                                component: PassThrough,
                                redirect: { name: 'Beta Dashboard Reports Overview' },
                                props: true,
                                children: [
                                    {
                                        path: '',
                                        name: 'beta-reports-layout',
                                        component: RetainerReportsLayout,
                                        props: true,
                                        children: [
                                            {
                                                path: '',
                                                name: 'Beta Dashboard Reports Overview',
                                                component: ReportsOverview,
                                                props: true,
                                            },
                                            {
                                                path: 'graph',
                                                name: 'Beta Dashboard Reports Graph',
                                                component: ReportsGraph,
                                                props: true,
                                            },
                                        ]
                                    },
                                    {
                                        path: 'listing/:date',
                                        component: PassThrough,
                                        name: 'listing-parent',
                                        props: true,
                                        children: [
                                            {
                                                path: '',
                                                name: 'Beta Dashboard Reports Listing',
                                                component: ReportsListing,
                                                props: true,
                                                meta: { fullWidth: true },
                                                children: [
                                                    {
                                                        path: 'invoice/add',
                                                        name: 'Admin - Add invoice',
                                                        component: AddInvoice,
                                                    },
                                                    {
                                                        path: 'invoice/:invoiceId',
                                                        name: 'Admin - Edit invoice',
                                                        component: AddInvoice,
                                                    },
                                                    {
                                                        path: ':clientId/edit',
                                                        name: 'Admin - Reports Overview - Reports',
                                                        component: RetainerNewClient,
                                                    },
                                                ]
                                            },
                                            {
                                                path: 'send-report',
                                                name: 'Send Report',
                                                component: SendReport,
                                                props: true
                                            },
                                            {
                                                path: 'edit/:dataid',
                                                name: 'Edit',
                                                component: EditListing,
                                                props: true
                                            },
                                        ]
                                    },
                                ]
                            },
                            {
                                path: 'account',
                                name: 'Beta Dashboard Details',
                                props: true,
                                component: Account
                            },
                            {
                                path: 'services',
                                name: 'Beta Dashboard Services',
                                props: true,
                                component: Services
                            },
                            {
                                path: 'send-mail',
                                name: 'Send mail',
                                component: SendMail,
                                props: true
                            },
                            {
                                path: 'retainer-settings',
                                name: 'Retainer Settings',
                                component: RetainerSettings,
                                props: true
                            },
                        ]
                    },
                    {
                        path: 'contacts',
                        name: 'Beta Dashboard Contacts',
                        props: true,
                        component: Contacts,
                        children: [
                            {
                                path: 'create',
                                name: 'Client - Add new contact',
                                component: AddNewUser,
                            },
                            {
                                path: 'profile/:userid',
                                name: 'Client - User profile',
                                component: UserProfile,
                                props: true
                            },
                        ]
                    },
                ]
            },
            {
                path: 'admin',
                component: AdminPassThrough,
                name: 'Admin Dashboard',
                props: true,
                children: [
                    {
                        path: '',
                        name: 'Beta Admin Dashboard',
                        component: AdminDashboard,
                        children: [
                            {
                                path: 'clients-needing-attention',
                                name: 'Admin - Clients needing attention',
                                component: ClientsNeedingAttention,
                            },
                            {
                                path: 'retainers-to-watch',
                                name: 'Admin - Retainers to watch',
                                component: RetainersToWatch,
                            },
                        ]
                    },
                    {
                        path: 'developer-options',
                        name: 'Developer Options',
                        component: DeveloperOptions,
                    },
                    {
                        path: 'team-overview',
                        name: 'Beta Team Overview',
                        component: TeamDashboard,
                    },
                    {
                        path: 'settings',
                        name: 'Beta App Settings',
                        redirect: { name: 'Application Settings' },
                        component: PassThrough,
                        props: true,
                        children: [
                            {
                                path: '',
                                name: 'Application Settings-layout',
                                props: true,
                                component: SettingsDashboard,
                                children: [
                                    {
                                        path: 'application-settings',
                                        name: 'Application Settings',
                                        props: true,
                                        component: ApplicationSettings,
                                    },
                                    {
                                        path: 'audit-logs',
                                        name: 'Audit Logs',
                                        props: true,
                                        component: AuditLogs,
                                    },
                                    {
                                        path: 'users',
                                        name: 'Admin Users',
                                        props: true,
                                        component: AdminUsers,
                                        children: [
                                            {
                                                path: 'create',
                                                name: 'Admin Users - Add new contact',
                                                component: AdminProfile,
                                            },
                                            {
                                                path: 'profile/:userid',
                                                name: 'Admin Users - User profile',
                                                component: AdminProfile,
                                                props: true
                                            },
                                        ]
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        path: 'clients',
                        name: 'Admin - Beta clients',
                        component: ClientRetainers,
                        children: [
                            {
                                path: 'create',
                                name: 'Admin - Add new client',
                                component: RetainerNewClient,
                            },
                            {
                                path: ':clientId',
                                name: 'Admin - Edit client',
                                component: RetainerNewClient,
                            },
                            {
                                path: 'profile/:userid',
                                name: 'Clients Page - User profile',
                                component: UserProfile,
                                props: true
                            },
                            {
                                path: 'profile/:userid',
                                name: 'Admin Users Client Page - User profile',
                                component: AdminProfile,
                                props: true
                            },
                            {
                                path: 'profile/:userid',
                                name: 'Admin Contacts Client Page - User profile',
                                component: UserProfile,
                                props: true
                            },
                        ]
                    },
                    {
                        path: 'retainers-overview/:date',
                        name: 'Retainers Overview',
                        component: RetainersOverviewv2,
                        props: true,
                        children: [
                            {
                                path: ':clientId/edit',
                                name: 'Admin - Retainer Overview - Edit client',
                                component: RetainerNewClient,
                            },
                            {
                                path: ':retainerId/invoice/add',
                                name: 'Admin - Retainer Overview - Add invoice',
                                component: AddInvoice,
                            },
                        ]
                    },
                    {
                        path: 'retainers',
                        name: 'Beta Retainers',
                        component: RetainersOverview,
                    },
                    {
                        path: '',
                        component: PassThrough,
                        name: 'Users',
                        redirect: { name: 'Beta Admin Contacts' },
                        props: true,
                        children: [
                            {
                                path: 'users',
                                name: 'Beta Admin Contacts',
                                component: Users,
                                props: true,
                                children: [
                                    {
                                        path: 'create',
                                        name: 'Admin - Add new contact',
                                        component: AddNewUser,
                                    },
                                    {
                                        path: 'profile/:userid',
                                        name: 'Admin - User profile',
                                        component: UserProfile,
                                        props: true
                                    },
                                ]
                            },
                        ]
                    },
                ]
            },
        ]
    },
];

let router = createRouter({
    history: createWebHistory(),
    routes,
});

// router/index.js
router.beforeEach((to, from, next) => {
    if (window !== window.top) {
      window.top.location.href = window.location.href;
      return; // Prevent navigation in the iframe
    }
    next();
});

export default router;